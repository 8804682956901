// Mapping.tsx
import { useState, useRef, useEffect, ChangeEvent } from "react";

import * as React from "react";
import { useUploadListContext } from "src/contexts/UploadListContext";
import KeyValuePair from "src/interfaces/KeyValuePair";
import Loading from "src/components/Loading";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import JSZip from "jszip";
import SaveInProgress from "src/components/SaveInProgress";

interface MappingProps {
  onStepChange: (step: number) => void; // Specify the type for the onStepChange function
}

const Mapping: React.FC<MappingProps> = ({ onStepChange }) => {
  const { projectId, campaignId } = useUploadListContext();
  const { acctId, loginId, csrfToken } = useAppSelector(selectAuth);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [step, setStep] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileUploadMessage, setFileUploadMessage] = useState("");
  const [ebrOption, setEbrOption] = useState<string>("MultiColumn");
  const [isLoading, setIsLoading] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [ebrDate, setEbrDate] = useState(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  });
  const [ebrType, setEbrType] = useState<string>("Permission");
  const [delimiter, setDelimiter] = useState<string>(",");
  const [delimiterSelected, setDelimiterSelected] = useState<string>(",");
  const [previewText, setPreviewText] = useState<string>("");
  const [columnSelections, setColumnSelections] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<string[][]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const delimiters: KeyValuePair[] = [
    { value: ",", key: "Comma (,)" },
    { value: "|", key: "Pipe (|)" },
    { value: ";", key: "Semi-colon (;)" },
    { value: "\t", key: "Tab" },
    { value: " ", key: "Space" },
    { value: "other", key: "Other" },
  ];

  const mappings: KeyValuePair[] = [
    { value: "0", key: "Ignore" },
    { value: "1", key: "Phone #" },
    { value: "2", key: "Date of Last Contact" },
    { value: "3", key: "Type" },
    { value: "4", key: "Reference # (Opt.)" },
    { value: "5", key: "Brand / Division (Opt.)" },
    { value: "6", key: "E-mail Address (Opt.)" },
    { value: "7", key: "Area" },
    { value: "8", key: "Local" },
  ];

  useEffect(() => {
    onStepChange(step);
  }, [step, onStepChange]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    console.log("handleFileChange triggered");
    const file = event.target.files ? event.target.files[0] : null;

    if (!file) {
      console.log("No file selected.");
      return;
    }

    setSelectedFile(file);
    //console.log("File selected: ", file.name);

    //alert("File selected: " + file.name);

    if (file.name.endsWith(".zip")) {
      //alert("Zip file detected. Processing the zip file.");
      //console.log("Zip file detected. Processing the zip file.");
      // Handle zip file
      const zip = new JSZip();
      try {
        const zipContent = await zip.loadAsync(file);
        let extractedFile: File | null = null;

        for (const relativePath in zipContent.files) {
          const zipEntry = zipContent.files[relativePath];
          console.log("Inspecting file in zip: ", relativePath);
          if (
            !extractedFile &&
            (relativePath.endsWith(".txt") || relativePath.endsWith(".csv"))
          ) {
            const fileData = await zipEntry.async("blob"); // Convert to Blob
            extractedFile = new File([fileData], relativePath);
            console.log("Extracted file from zip: ", extractedFile.name);
          }
        }

        if (extractedFile) {
          setStep(1);
          readFile(extractedFile); // Process the extracted file
        } else {
          alert("No .txt or .csv file found inside the zip.");
        }
      } catch (error) {
        console.error("Failed to unzip the file", error);
        alert("Failed to unzip the file. Please try again.");
      }
    } else {
      // Handle non-zip file
      if (ebrOption === "SingleColumn") {
        handleUpload();
        event.target.value = "";
      } else {
        setStep(1);
        readFile(file);
      }
    }
  };

  const readFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const text = e.target?.result as string;
      const lines = text.split(/\r\n|\n/).slice(0, 5);
      const { mostCommonDelimiter, columnSuggestions } =
        inferDelimiterAndColumnTypes(lines);
      setDelimiter(mostCommonDelimiter);
      setDelimiterSelected(mostCommonDelimiter);
      setPreviewText(lines.join("\n"));
      // Update tableRows and columnSelections based on the file content and inferred types
      const rows = lines.map((line) => line.split(mostCommonDelimiter));
      setTableRows(rows);
      setColumnSelections(columnSuggestions);
    };
    reader.readAsText(file);
  };

  const inferDelimiterAndColumnTypes = (lines: string[]) => {
    // Potential delimiters and their count
    const delimiterCounts: { [delimiter: string]: number } = {};
    const delimiters = [",", "|", ";", "\t", " "];

    lines.forEach((line) => {
      delimiters.forEach((delimiter) => {
        const count = (line.match(new RegExp(`\\${delimiter}`, "g")) || [])
          .length;
        delimiterCounts[delimiter] = (delimiterCounts[delimiter] || 0) + count;
      });
    });

    const mostCommonDelimiter = Object.entries(delimiterCounts).sort(
      (a, b) => b[1] - a[1],
    )[0][0];

    const phoneNumberRegex = /^\d{10}$/;
    const threeDigitRegex = /^\d{3}$/;
    const sevenDigitRegex = /^\d{7}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const dateRegex =
      /\d{4}-\d{2}-\d{2}(?:\s\d{2}:\d{2}:\d{2})?|^\d{1,2}\/\d{1,2}\/\d{4}$/;
    const singleCharRegex = /^[A-Za-z0-9]$/;
    const typeWords = [
      "Sale",
      "Inquiry",
      "Permission",
      "Recent Sale",
      "Newspaper Trial",
      "1",
      "2",
      "3",
      "4",
      "5",
    ];

    let columnSuggestions = lines[0].split(mostCommonDelimiter).map(() => "0");

    const firstRowIsHeader = lines[0]
      .split(mostCommonDelimiter)
      .every(
        (value) =>
          !phoneNumberRegex.test(value) &&
          !threeDigitRegex.test(value) &&
          !sevenDigitRegex.test(value) &&
          !emailRegex.test(value) &&
          !dateRegex.test(value) &&
          !singleCharRegex.test(value) &&
          !typeWords.includes(value),
      );

    // If the first row is a header, start checking from the second row
    const dataLines = firstRowIsHeader ? lines.slice(1) : lines;

    dataLines.forEach((line) => {
      const columns = line.split(mostCommonDelimiter);

      columns.forEach((value, index) => {
        // Check for a 10-digit phone number
        if (phoneNumberRegex.test(value)) {
          columnSuggestions[index] = "1"; // Map to Phone #
        }
        // Check for 3-digit and 7-digit phone number parts
        else if (threeDigitRegex.test(value)) {
          columnSuggestions[index] = "7"; // Map to Area
        } else if (sevenDigitRegex.test(value)) {
          columnSuggestions[index] = "8"; // Map to Local
        } else if (emailRegex.test(value)) {
          columnSuggestions[index] = "6"; // Map to Email Address
        } else if (dateRegex.test(value)) {
          columnSuggestions[index] = "2"; // Map to Date of Last Contact
        }
        // Check for Type
        else if (singleCharRegex.test(value) || typeWords.includes(value)) {
          columnSuggestions[index] = "3"; // Map to Type
        }
      });
    });

    return { mostCommonDelimiter, columnSuggestions };
  };

  const handleColumnSelectionChange = (index: number, value: string) => {
    const newSelections = [...columnSelections];
    newSelections[index] = value;
    setColumnSelections(newSelections);
  };

  const handleTextareaClick = (
    e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLTextAreaElement;
    const cursorPosition = target.selectionStart;
    const clickedChar = previewText[cursorPosition - 1] || "";

    if (clickedChar.trim().length) {
      setDelimiterSelected("other");
      setDelimiter(clickedChar);
    }
  };

  const handleDelimiterChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (value === "other") {
      // Delimiter is set by the handleTextareaClick function
    } else {
      setDelimiter(value);
      setDelimiterSelected(value);
    }
  };

  const handleReset = () => {
    setDelimiter(",");
    setDelimiterSelected(",");
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setStep(0);
    setPreviewText("");
  };

  const handleNextStep = () => {
    setStep(2);
  };

  const handleEbrOptionChange = (value: string) => {
    setEbrOption(value);
    setFileUploadMessage("");
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    setFileUploadMessage("");
    setSaveInProgress(true);
    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("projId", projectId.toString());
    formData.append("CampaignId", campaignId.toString());
    formData.append("option", ebrOption);
    formData.append("date", ebrDate);
    formData.append("type", ebrType);
    const selectedDelimiter = delimiter === "\t" ? "Tab" : delimiter;
    formData.append("delimiter", selectedDelimiter);

    if (ebrOption !== "SingleColumn") {
      formData.append("mappings", JSON.stringify(columnSelections));
    }

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${import.meta.env.VITE_APP_API_URL}/main/ebrmaster/upload?projId=${projectId}&CampaignId=${campaignId}`,
      true,
    );

    // Set up headers
    xhr.setRequestHeader("acctId", acctId ?? "");
    xhr.setRequestHeader("loginId", loginId ?? "");
    xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken ?? "");

    // Track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentComplete);
      }
    };

    // Handle the request completion
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const responseData = JSON.parse(xhr.responseText);
        if (responseData.type === "SUCCESS") {
          console.log("File upload successful", responseData);
          // Reset the form
          setSelectedFile(null);
          setFileUploadMessage("Files uploaded successfully.");
          setStep(0);
          setPreviewText("");
          setSaveInProgress(false);
          setUploadProgress(100);
        } else {
          alert(
            `File upload failed. Please try again. ${responseData.message}`,
          );
        }
      } else {
        console.error(`Server responded with ${xhr.status}`);
        alert(`File upload failed with status: ${xhr.status}`);
      }
      setIsUploading(false);
    };

    // Handle any errors
    xhr.onerror = () => {
      console.error("File upload failed");
      alert("File upload failed. Please try again.");
      setIsUploading(false);
    };

    // Send the form data
    xhr.send(formData);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {step === 0 && (
        <>
          <div className="card">
            <div className="card-header">Upload into Project's EBRMaster</div>
            <div className="card-body">
              <div>
                We accept either a text file or a zipped text file. Textfiles
                must end in .txt and Zipfiles must end in .zip. If you upload a
                Zipfile, the Zipfile must contain only one file. Choose one of
                these two types of files:
              </div>
              <br />
              <table className="w-100">
                <tbody>
                  <tr className="align-top">
                    <td className="p-3">
                      <div className="lh-base">
                        <input
                          id="multi-column-upload"
                          type="radio"
                          className="me-2 form-check-input"
                          value="MultiColumn"
                          checked={ebrOption === "MultiColumn"}
                          onChange={() => handleEbrOptionChange("MultiColumn")}
                        />
                        <b>
                          <label htmlFor="multi-column-upload">
                            Multi-column with Dates & EBR type for each number
                          </label>
                        </b>
                        <br />
                        The file uploaded can be either fixed-width or
                        delimited. After you upload your file you will be
                        prompted to select the delimiter or for fixed-width
                        specify the column widths. The file must contain a
                        minimum of 3 columns: Phone Number, Date of Last Contact
                        and Type. Type can either be the words Sale, Inquiry,
                        Permission (aka Express Consent), Recent Sale, Newspaper
                        Trial or abbreviated as S, I, P, R, N respectively.
                      </div>
                    </td>
                    <td className="p-3">
                      <div className="lh-base">
                        <input
                          id="single-column-upload"
                          type="radio"
                          className="me-2 form-check-input"
                          value="SingleColumn"
                          checked={ebrOption === "SingleColumn"}
                          onChange={() => handleEbrOptionChange("SingleColumn")}
                        />
                        <b>
                          <label htmlFor="single-column-upload">
                            Single column of numbers all to use this type and
                            date:
                          </label>
                        </b>
                        <br />
                        The uploaded file must contain phone numbers only. Any
                        additional data, such as date or EBR type, will override
                        the information below.
                        <br />
                        Date of Last Contact: <br />
                        <input
                          className="form-control"
                          type="date"
                          value={ebrDate}
                          onChange={(e) => setEbrDate(e.target.value)}
                        />
                        <br />
                        EBR Type: <br />
                        <select
                          className="form-control form-select"
                          value={ebrType}
                          onChange={(e) => setEbrType(e.target.value)}
                        >
                          <option value="1">Sale</option>
                          <option value="2">Inquiry</option>
                          <option value="3">Permission</option>
                          <option value="4">Recent Sale</option>
                          <option value="5">Newspaper Trial</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3" colSpan={2}>
                      <button
                        className="btn btn-primary"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        Upload File
                      </button>
                      <div className="ps-3 text-success">
                        {fileUploadMessage}
                      </div>
                      <SaveInProgress isVisible={saveInProgress} />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept=".txt, .csv, .zip"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {step === 1 && (
        <>
          <h6>EBRMaster Upload Step #{step}</h6>
          <div>
            Before we can upload your file you must help us determine the file
            format you are using. Please fill out the form below then click
            'Next'.
          </div>
          <br />
          <div className="card">
            <div className="card-header">Data Preview:</div>
            <div className="card-body">
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-12">
                    To select the appropriate Delimiting character either type
                    it into the text box below or select the character in the
                    preview box below.{delimiter}
                  </div>
                  <div className="col-4">
                    Delimiter:
                    <select
                      value={delimiterSelected}
                      onChange={handleDelimiterChange}
                      className="form-control form-select"
                      title="Select the delimiter character"
                    >
                      {delimiters.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.key}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-4">
                    or custom
                    <input
                      type="text"
                      className="form-control"
                      value={delimiter !== "other" ? delimiter : ""}
                      onChange={handleDelimiterChange}
                      title="Type the delimiter character here"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <textarea
                      className="form-control"
                      rows={5}
                      value={previewText}
                      readOnly
                      onClick={handleTextareaClick}
                      style={{ fontFamily: "monospace", cursor: "pointer" }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <button onClick={handleNextStep} className="btn btn-primary me-2">
            Next
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="btn btn-secondary me-2"
          >
            Reset
          </button>

          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-secondary me-2"
          >
            Cancel
          </button>
        </>
      )}

      {step === 2 && (
        <>
          <h6>EBRMaster Upload Step #{step}</h6>
          <div>Choose which columns contain which data.</div>
          <table className="table">
            <thead>
              <tr>
                {tableRows[0].map((_, colIndex) => (
                  <th key={colIndex} className="bg-secondary">
                    <select
                      title="Select the data type for this column"
                      className="form-control form-select"
                      value={columnSelections[colIndex] || "0"} // Default to "Ignore"
                      onChange={(e) =>
                        handleColumnSelectionChange(colIndex, e.target.value)
                      }
                    >
                      {mappings.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.key}
                        </option>
                      ))}
                    </select>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((col, colIndex) => (
                    <td key={colIndex}>{col}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          {/* Add Progress Bar */}
          {isUploading && (
            <div className="progress my-3" style={{ height: "20px" }}>
              <div
                className="progress-bar bg-primary"
                role="progressbar"
                style={{
                  width: `${uploadProgress}%`,
                  transition: "width 0.4s ease-in-out",
                }}
                aria-valuenow={uploadProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {uploadProgress}%
              </div>
            </div>
          )}

          <button
            onClick={handleUpload}
            className="btn btn-secondary me-2"
            disabled={isUploading} // Disable while uploading
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>

          <button
            type="button"
            onClick={handleBack}
            className="btn btn-secondary me-2"
            disabled={isUploading}
          >
            Back
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-secondary me-2"
            disabled={isUploading}
          >
            Cancel
          </button>
        </>
      )}
    </>
  );
};

export default Mapping;
