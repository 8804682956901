import * as React from "react";

export enum SessionStatuses {
  Received = 1,
  Imported,
  Queued,
  Scrubbing,
  FinishedScrubbing,
  GeneratingOutput,
  Completed,
  Cancelled,
  WaitingToEmailBatch,
  ExceedsScrubLimit,
  AssistanceRequired,
  AssistanceRequested,
  Rescrubbed,
  RetroscrubbedSupercedingSessionCreated,
  CalculatingBillableRecords,
  Processing,
  ExceedCreditLimit,
}

type StatusInfo = {
  description: string;
  icon: string;
  showDescription: boolean;
};

const StatusDetails: Record<SessionStatuses, StatusInfo> = {
  [SessionStatuses.Received]: {
    description: "Received",
    icon: "bi-box-arrow-in-right",
    showDescription: true,
  },
  [SessionStatuses.Imported]: {
    description: "Imported",
    icon: "bi-upload",
    showDescription: false,
  },
  [SessionStatuses.Queued]: {
    description: "Queued",
    icon: "bi-clock-history",
    showDescription: false,
  },
  [SessionStatuses.Scrubbing]: {
    description: "Scrubbing",
    icon: "bi-clipboard2-check",
    showDescription: false,
  },
  [SessionStatuses.FinishedScrubbing]: {
    description: "Finished Scrubbing",
    icon: "bi-list-check",
    showDescription: true,
  },
  [SessionStatuses.GeneratingOutput]: {
    description: "Generating Output",
    icon: "bi-ui-checks-grid",
    showDescription: false,
  },
  [SessionStatuses.Completed]: {
    description: "Completed",
    icon: "bi-check-circle-fill",
    showDescription: false,
  },
  [SessionStatuses.Cancelled]: {
    description: "Cancelled",
    icon: "bi-upload",
    showDescription: true,
  },
  [SessionStatuses.WaitingToEmailBatch]: {
    description: "Identifying Reassigned Numbers",
    icon: "bi-envelope-exclamation",
    showDescription: true,
  },
  [SessionStatuses.ExceedsScrubLimit]: {
    description: "Exceeds Scrub Limit",
    icon: "bi-three-dots",
    showDescription: true,
  },
  [SessionStatuses.AssistanceRequired]: {
    description: "Invalid File Format",
    icon: "bi-person-raised-hand",
    showDescription: true,
  },
  [SessionStatuses.AssistanceRequested]: {
    description: "Assistance Requested",
    icon: "bi-person-raised-hand",
    showDescription: true,
  },
  [SessionStatuses.Rescrubbed]: {
    description: "Rescrubbed",
    icon: "bi-arrow-clockwise",
    showDescription: true,
  },
  [SessionStatuses.RetroscrubbedSupercedingSessionCreated]: {
    description: "Retroscrubbed Superceding Session Created",
    icon: "bi-bootstrap-reboot",
    showDescription: true,
  },
  [SessionStatuses.CalculatingBillableRecords]: {
    description: "Calculating Billable Records",
    icon: "bi-receipt",
    showDescription: true,
  },
  [SessionStatuses.Processing]: {
    description: "Processing",
    icon: "bi-clock-history",
    showDescription: true,
  },
  [SessionStatuses.ExceedCreditLimit]: {
    description: "Exceed Credit Limit",
    icon: "bi-bricks",
    showDescription: true,
  },
};

export const StatusComponent: React.FC<{ statusId: number }> = React.memo(
  ({ statusId }) => {
    const statusDetails = StatusDetails[statusId as SessionStatuses];
    const isCompleted = SessionStatuses.Completed === statusId;
    const classCompleted = isCompleted
      ? "badge text-bg-success"
      : "badge text-bg-secondary";

    return (
      <h6 className="mb-0">
        STATUS:{" "}
        {!statusDetails.showDescription && (
          <i
            className={`bi ${statusDetails.icon}`}
            style={{ marginLeft: "5px", marginRight: "5px" }}
          ></i>
        )}
        <span className={classCompleted}> {statusDetails.description}</span>
      </h6>
    );
  },
);
