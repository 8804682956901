import { useState, useEffect, useRef, useCallback, ChangeEvent } from "react";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import { IInternalDNC, CpaSection } from "src/interfaces/Main/IInternalDNC";
import SaveInProgress from "src/components/SaveInProgress";
import DownloadDNCDNMDatabases from "./DownloadDNCDNMDatabases";
import UndoDNC from "./UndoDNC";
import SearchDNCDatabase from "./SearchDNCDatabase";

const InternalDNCMain = () => {
  const { projectId, campaignId } = useUploadListContext();
  const { acctId, loginId, csrfToken } = useAppSelector(selectAuth);
  const importTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const undoDNCRef = useRef<{ updateUndoDetails: () => void }>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [fileUploadMessage, setFileUploadMessage] = useState("");
  const [formValues, setFormValues] = useState<IInternalDNC>();
  const [access, setAccess] = useState<CpaSection>();
  const [fetchedRegions, setFetchedRegions] = useState<
    { state: string; stateName: string }[]
  >([]);

  const fetchComponentLoadItems = useCallback(async () => {
    setIsLoading(true);
    try {
      const apiUrl = `main/dncdnm?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        if (!response.cpaSection.Add) {
          window.location.href = "/main/upload-list";
        }
        setFormValues(response);
        setFetchedRegions(response.regions);
        setAccess(response.cpaSection);
      }
    } catch (error) {
      console.error("Failed to fetch sub menu:", error);
    } finally {
      setIsLoading(false);
    }
  }, [projectId, campaignId]);

  useEffect(() => {
    if (projectId && campaignId) {
      fetchComponentLoadItems();
      updateUndoDetails();
    }
  }, [projectId, campaignId, fetchComponentLoadItems]);

  const updateUndoDetails = () => {
    if (undoDNCRef.current) {
      undoDNCRef.current.updateUndoDetails();
    }
  };

  const handleImportClick = async (addToMaster = false) => {
    if (importTextAreaRef.current && importTextAreaRef.current.value) {
      const input = importTextAreaRef.current.value.trim();
      setIsLoading(true);
      const phoneNumbers = input.split(/[\n,;|]+/).filter(Boolean);

      // Ensure each line has 10 digits. We do not care how they are formatted. Eg this is acceptable
      // (503) 936-7187
      // 503.936.7187
      // 503-936-7187
      const allValid = phoneNumbers.every(
        (number) => (number.match(/\d/g) || []).length === 10,
      );

      if (!allValid) {
        alert(
          "Please enter valid 10-digit phone numbers, separated by new lines, commas, semicolons, or pipes.",
        );
        setIsLoading(false);
        return;
      }
      const numbersToPost = phoneNumbers.map((number) =>
        Number(number.replace(/\D/g, "")),
      );

      const apiUrl = `main/dncdnm/import?projId=${projectId}&campaignId=${campaignId}&addToMaster=${addToMaster}`;
      const response = await callApi(apiUrl, HttpMethod.POST, numbersToPost);
      if (response.type.toLowerCase() === "success") {
        updateUndoDetails();
        if (importTextAreaRef.current) {
          importTextAreaRef.current.value = "";
        }
      } else {
        console.log("API Response:", response);
      }
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setFileUploadMessage("");
    setSaveInProgress(true);
    const files = event.target.files;
    if (!files || files.length === 0) {
      console.error("No files selected.");
      return;
    }
    const baseUrl = import.meta.env.VITE_APP_API_URL;
    const formData = new FormData();

    Array.from(files).forEach((file, index) => {
      formData.append("files", file);
    });

    try {
      const response = await fetch(
        `${baseUrl}/main/dncdnm/upload?projId=${projectId}&campaignId=${campaignId}`,
        {
          method: "POST",
          body: formData,
          headers: {
            acctId: acctId ?? "",
            loginId: loginId ?? "",
            "X-CSRF-TOKEN": csrfToken ?? "",
          },
          credentials: "include",
        },
      );

      if (response.ok) {
        // const responseData = await response.json();
        setFileUploadMessage("Files upload successfully.");
      } else {
        throw new Error(`Server responded with ${response.status}`);
      }
    } catch (error) {
      console.error("Files upload failed", error);
    }
    setSaveInProgress(false);
  };

  if (isLoading || access === undefined) {
    return <Loading />;
  }

  return (
    <>
      <div className="debug-access d-none">
        <h5>Access</h5>
        <pre>{JSON.stringify(access, null, 2)}</pre>
      </div>
      <div className="d-flex justify-content-between">
        <div className="card col-6">
          <div className="card-header">
            Import Phone Numbers into Project's Do Not Call database{" "}
            <small>
              <i>(applies to all its campaigns)</i>
            </small>
          </div>
          <div className="card-body">
            {access?.Add && (
              <>
                <textarea
                  rows={4}
                  className="form-control"
                  ref={importTextAreaRef}
                  title="Enter 10-digit phone numbers"
                ></textarea>
                <button
                  onClick={() => handleImportClick(false)}
                  className="btn btn-primary mt-2 me-2"
                >
                  <i className="bi bi-arrow-up-circle"></i> Import
                </button>
                {!formValues?.isMaster && (
                  <button
                    onClick={() => handleImportClick(true)}
                    className="btn btn-primary mt-2"
                  >
                    Import to Project & Master
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        {access.Undo && (
          <div>
            <UndoDNC ref={undoDNCRef} />
          </div>
        )}
      </div>

      <SearchDNCDatabase
        access={access}
        fetchedRegions={fetchedRegions}
        onActionComplete={updateUndoDetails}
      />

      {access.Upload && (
        <div className="card mt-5">
          <div className="card-header">
            Upload to Selected Project's Databases
          </div>
          <div className="card-body">
            <p>
              We accept either a text file or a zipped text file. Textfiles must
              end in .txt and Zipfiles must end in .zip. If you upload a
              Zipfile, the Zipfile must contain only one file. Zipfiles at this
              time may not be password protected.
            </p>
            <label className="pe-3">
              <input
                type="checkbox"
                className="form-check-input"
                checked
                disabled
              />
              <span className="ps-2">Do Not Call</span>
            </label>
          </div>
          <div className="card-footer">
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept=".txt, .zip"
              title="Upload File"
            />
            <button
              className="btn btn-primary"
              onClick={() => fileInputRef.current?.click()}
            >
              Upload File
            </button>
            <div className="ps-3 text-success">{fileUploadMessage}</div>
            <SaveInProgress isVisible={saveInProgress} />
            <div></div>
          </div>
        </div>
      )}

      <DownloadDNCDNMDatabases access={access} />
    </>
  );
};

export default InternalDNCMain;
