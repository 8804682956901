import { useState } from "react";
import * as React from "react";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";
import { formatNumber } from "src/utils/formatUtils";
import KeyValuePair from "src/interfaces/KeyValuePair";
import { CpaSection } from "src/interfaces/Main/IInternalDNC";
import Warning from "src/components/Warning";

interface DownloadDNCDNMDatabasesProps {
  access: CpaSection | undefined;
}

const DownloadDNCDNMDatabases: React.FC<DownloadDNCDNMDatabasesProps> = ({
  access,
}) => {
  const { projectId, campaignId } = useUploadListContext();
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [countResults, setCountResults] = useState<KeyValuePair>();
  const [isLoading, setIsLoading] = useState(false);
  const [emailValues, setEmailValues] = useState<Record<number, string>>({});
  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const rows = [
    { id: 1, type: "DNC" },
    { id: 2, type: "DNM" },
  ];

  const handleGetCountButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    rowId: number,
  ) => {
    event.preventDefault();
    setSuccessMessage("");
    setWarningMessage("");
    setIsLoading(true);
    setCountResults(undefined);
    const apiUrl = `main/dncdnm/getcount?projId=${projectId}&campaignId=${campaignId}&row=${rowId}`;
    try {
      const response = await callApi(apiUrl, HttpMethod.GET);
      setActiveRow(rowId);
      setCountResults(response);
    } catch (error) {
      setWarningMessage("An error occurred while fetching the counts");
      console.error("Failed to fetch count:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (rowId: number) => {
    setSuccessMessage("Processing...Please wait.");
    setWarningMessage("");
    try {
      const apiUrl = `main/dncdnm/download?projId=${projectId}&campaignId=${campaignId}&row=${rowId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      const csvData = response.message;
      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      //Match the file name to the Tcl App

      const fileNamePrefix = rowId === 1 ? "DNC" : "DNM";
      // Abhi: We want to show the local date instead of UTC date
      //const date = new Date().toISOString().slice(0, 10);
      const date = new Date().toLocaleDateString("en-CA");
      const fileName = `${fileNamePrefix}_${date}_${projectId}.csv`;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the object URL and removing the link element
      window.URL.revokeObjectURL(downloadUrl);
      link.parentNode?.removeChild(link);
      setSuccessMessage("");
    } catch (error) {
      setWarningMessage("An error occurred while downloading the file");
      console.error("Download failed:", error);
    }
  };

  const handleDeleteAllClick = async (rowId: number) => {
    setSuccessMessage("");
    setWarningMessage("");
    try {
      setIsLoading(true);
      const apiUrl = `main/dncdnm/removeall?projId=${projectId}&campaignId=${campaignId}&row=${rowId}`;
      const response = await callApi(apiUrl, HttpMethod.DELETE);
      if (response.type === "SUCCESS") {
        if (activeRow === rowId) {
          await handleGetCountButtonClick(
            { preventDefault: () => {} } as React.MouseEvent<HTMLButtonElement>,
            rowId,
          );
        }
      } else {
        console.log("An unknown error occurred");
      }
      setIsLoading(false);
    } catch (error) {
      setWarningMessage("An error occurred while deleting the records");
      console.error("Error deleting records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailInputChange = (id: number, value: string) => {
    setEmailValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleEmailClick = async (rowId: number) => {
    setWarningMessage("");
    const email = emailValues[rowId];
    setSuccessMessage("Processing...Please wait.");
    if (!email) {
      setSuccessMessage("Please enter a valid email address to continue.");
      return;
    }
    //console.log(`Email for id ${rowId}: ${email}`);

    try {
      const apiUrl = `main/dncdnm/email?projId=${projectId}&campaignId=${campaignId}&row=${rowId}&email=${email}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response.type.toLowerCase() === "success") {
        setEmailValues((prev) => ({
          ...prev,
          [rowId]: "",
        }));
      }
      setSuccessMessage(`Email sent successfully to ${email}`);
    } catch (error) {
      setWarningMessage("An error occurred while sending the email");
      console.error("Send Email failed:", error);
    }
  };

  if (isLoading || access === undefined) {
    return <Loading />;
  }

  return (
    <>
      <div className="card mt-5">
        <div className="card-header">Download DNC/DNM Databases</div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <td>Type</td>
                <td>No of Records</td>
                <td>Last Updated</td>
                <td>
                  {successMessage && <div>{successMessage}</div>}
                  <Warning message={warningMessage} />
                </td>
              </tr>
            </thead>
            <tbody>
              {rows.map(({ id, type }) => (
                <tr key={id}>
                  <td>{type}</td>
                  {activeRow === id ? (
                    <>
                      <td>
                        {formatNumber(parseFloat(countResults?.key ?? "0"))}
                      </td>
                      <td>{countResults?.value}</td>
                    </>
                  ) : (
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => handleGetCountButtonClick(e, id)}
                        >
                          Get Count
                        </button>
                      </div>
                    </td>
                  )}
                  <td>
                    <div className="d-flex align-items-center">
                      {access.Download && (
                        <button
                          className="btn btn-primary me-3"
                          onClick={() => handleDownload(id)}
                        >
                          Download
                        </button>
                      )}
                      {access.Clear && (
                        <button
                          className="btn btn-primary me-3"
                          onClick={() => handleDeleteAllClick(id)}
                        >
                          Delete All
                        </button>
                      )}

                      {access.Email && (
                        <div className="d-flex flex-column align-items-start-start">
                          <div>
                            <label
                              className="form-label mb-0"
                              htmlFor="email-on-download"
                            >
                              Email link to download:
                            </label>
                          </div>
                          <div className="d-flex">
                            <input
                              id="email-on-download"
                              type="text"
                              className="form-control me-2"
                              value={emailValues[id] || ""}
                              onChange={(e) =>
                                handleEmailInputChange(id, e.target.value)
                              }
                              placeholder="Email"
                            />
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => handleEmailClick(id)}
                            >
                              Email
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DownloadDNCDNMDatabases;
