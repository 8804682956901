import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import Styles from "./ReassignedScrubItem.module.css";
import { formatNumber, formatDateTime } from "src/utils/formatUtils";
import { TCPAAuthoritySessionsProps } from "./Common";
import { SessionStatuses, StatusComponent } from "./Components/Status";
import {
  ReassignedProductComponent,
  ReassignedProducts,
} from "./Components/ReassignedProduct";
import axios from "axios";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import { useUploadListContext } from "src/contexts/UploadListContext";
import UserRoleIcon from "src/components/UserRoleIcon";

interface ReassignedScrubItemProps {
  session: TCPAAuthoritySessionsProps;
}
const cssClassForStatsColumn = "col-12 col-md-4 col-lg-4 col-xl-3 d-flex";

export const ReassignedScrubItem: React.FC<ReassignedScrubItemProps> =
  React.memo(({ session }) => {
    const baseUrl = import.meta.env.VITE_APP_API_URL;
    const { acctId, loginId, csrfToken } = useAppSelector(selectAuth);
    const [isLoading, setIsLoading] = useState(false);
    const { campaignId } = useUploadListContext();

    const getMimeType = useMemo(
      () => (extension: string) => {
        extension = extension.toLowerCase();
        switch (extension) {
          case "txt":
            return "text/plain";
          case "csv":
            return "text/csv";
          case "xls":
            return "application/vnd.ms-excel";
          case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          case "zip":
            return "application/zip";
          case "rar":
            return "application/x-rar-compressed";
          case "7z":
            return "application/x-7z-compressed";
          default:
            return "application/octet-stream";
        }
      },
      [],
    );

    const handleDownload = useCallback(async () => {
      if (!session.downloadPath) {
        alert("Please enter a file path");
        return;
      }

      try {
        setIsLoading(true);
        const apiUrl = `${baseUrl}/Sessions/DownloadFile?path=${encodeURIComponent(session.downloadPath)}&sessionId=${session.sessionId}&campaignId=${campaignId}`;
        const fileNameSplit = session.downloadPath.split("/");
        const filename = decodeURIComponent(
          fileNameSplit[fileNameSplit.length - 1],
        );
        const extensionSplit = filename.split(".");
        // Return the last part of the array, which is the extension
        const extensionName =
          extensionSplit.length > 1 ? extensionSplit.pop() : "";

        const response = await axios({
          url: apiUrl,
          method: "GET",
          responseType: "blob", // Important
          headers: {
            "Content-Type": getMimeType(extensionName!),
            loginId: loginId,
            acctId: acctId,
            csrfToken: csrfToken,
          },
        });

        const blob = new Blob([response.data], {
          type: getMimeType(extensionName!),
        });
        const url = window.URL.createObjectURL(blob);

        // Create a link element to initiate the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }, [
      session.downloadPath,
      session.sessionId,
      baseUrl,
      campaignId,
      getMimeType,
      loginId,
      acctId,
      csrfToken,
    ]);
    const sessionCardClass = `${Styles["session-card"]} ${session.receivedBy === "w" ? Styles["session-card-web-upload"] : Styles["session-card-sftp-upload"]} card text-center mb-4`;
    return (
      <div className={`${sessionCardClass}`}>
        <div className={`${Styles["session-card-header"]} card-header`}>
          <div className="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
            <div className="fw-bold">
              <i
                className={`bi ${session.receivedBy === "f" ? "bi-cloud-upload" : session.receivedBy === "a" ? "bi-braces" : "bi-window-plus"} me-2`}
              ></i>
              {session.fileName}
            </div>

            <div className="d-flex align-items-center">
              <i className="bi bi-calendar2 me-2"></i>{" "}
              {formatDateTime(session.addedOn ?? "")}
            </div>

            <div className="d-flex align-items-center">
              <span className="fw-bold me-1">Added By:</span>
              <UserRoleIcon
                Role={session.addedByRoleId}
                Status={session.addedByUserStatusId}
              />
              {session.userName}
            </div>
            <div className={`${Styles["upload-method-status"]} fw-bold`}>
              {session.receivedBy === "f"
                ? "SFTP"
                : session.receivedBy === "a"
                  ? "API"
                  : "WEB"}
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="d-flex align-items-center border-bottom pb-1">
            <div className="d-flex align-items-center p-1 w-100 justify-content-between">
              {session.fromSandbox && (
                <span className="alert alert-danger m-0 p-0 ps-1 pe-1">
                  Processed in Sandbox
                </span>
              )}
              <StatusComponent statusId={session.statusId} />
              <ReassignedProductComponent
                reassignedProductId={session.reassignedProductId}
              />
              <h6 className="mb-0">
                Excluded Mobile to Mobile Ports:{" "}
                <span className="badge text-bg-secondary">
                  {" "}
                  {session.excludeMobileToMobilePorts ? "Yes" : "No"}
                </span>
              </h6>
            </div>
          </div>
          <div className={`row ${Styles["completed-session-card"]}`}>
            <div className={cssClassForStatsColumn}>
              <div className="col text-start pt-2">
                Total Records:
                <br />
                Clean:
                <br />
                Reassigned:
              </div>
              <div className="col text-start pt-2">
                {formatNumber(session.totalRecords ?? 0)}
                <br />
                {formatNumber(session.totalClean ?? 0)}
                <br />
                {formatNumber(session.totalIsReassigned ?? 0)}
              </div>
            </div>

            <div className={cssClassForStatsColumn}>
              <div
                className={`col ${Styles["border-start-sm"]} text-start pt-md-2 ps-md-2`}
              >
                Valid:
                <br />
                Invalid:
                <br />
                {session.reassignedProductId ===
                  ReassignedProducts.TCPAReassignedAuthority && <>Wireless:</>}
              </div>
              <div className="col text-start pt-md-2">
                {formatNumber(session.totalValid ?? 0)}
                <br />
                {formatNumber(session.totalInvalid ?? 0)}
                <br />
                {session.reassignedProductId ===
                  ReassignedProducts.TCPAReassignedAuthority && (
                  <>{formatNumber(session.totalLineTypeWireless ?? 0)}</>
                )}
              </div>
            </div>

            {session.reassignedProductId ===
              ReassignedProducts.TCPAReassignedAuthority && (
              <div className={cssClassForStatsColumn}>
                <div
                  className={`col ${Styles["border-start-sm"]} text-start pt-md-2 ps-md-2`}
                >
                  Voip:
                  <br />
                  Paging:
                  <br />
                  Landlines:
                </div>
                <div className="col text-start pt-md-2">
                  {formatNumber(session.totalLineTypeVoIP ?? 0)}
                  <br />
                  {formatNumber(session.totalLineTypePaging ?? 0)}
                  <br />
                  {formatNumber(session.totalLineTypeLandline ?? 0)}
                </div>
              </div>
            )}
            {session.reassignedProductId !==
              ReassignedProducts.TCPAReassignedAuthority && (
              <div className="col-12 col-xl-3 d-flex"></div>
            )}
            <div className="col-12 col-xl-3 d-flex">
              <div
                className={`col ${Styles["border-start-lg"]} text-start pt-2 ps-xl-2 d-flex flex-column flex-sm-row flex-xl-column gap-sm-3 gap-xl-0`}
              >
                {session.downloadPath &&
                session.statusId === SessionStatuses.Completed ? (
                  <>
                    {isLoading ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleDownload}
                      >
                        DOWNLOAD OUTPUT{" "}
                        {session.outputFileSize
                          ? `(${Number((session.outputFileSize / 1024).toFixed()).toLocaleString()}KB)`
                          : ""}
                      </button>
                    )}
                    {session.lastDownloadedByUserId && (
                      <>
                        <div>
                          <b>Last downloaded:</b> <br />
                          {formatDateTime(session.lastDownloadedDate ?? "")}
                        </div>
                        <div>
                          <b>Downloaded by:</b>
                          <br />

                          <UserRoleIcon
                            Role={session.downloadedByRoleId}
                            Status={session.downloadedByStatusId}
                          />
                          {session.userName}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <b>{"File is not ready for download."}</b>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
